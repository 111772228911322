import {
    GET_ALL_COLLECTIONS,
    SET_COLLECTION_SEARCH,
    SET_COLLECTION_CATEGORY,
    SET_COLLECTION_OFFSET,
    SET_COLLECTION_FILTER,
    RESET_COLLECTION_DATA,
    SET_COLLECTION_TIMERANGE,
    SET_COLLECTION_CHAIN,
    SET_COLLECTION_SORT,
    RESET_COLLECTION_OFFSET
} from "../action/actionConstants";

const initialState = {
    // collectionId: '',
    collectionData: [],
    collectionSearch: '',
    categoryFilter: '',
    chainFilter: '',
    timeRangeFilter: '',
    offset: 0,
    filter: {},
    sort: 'NEWEST'
};
const AllCollections = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ALL_COLLECTIONS:
            return {
                ...state,
                collectionData: action.payload
            };
        case SET_COLLECTION_SEARCH:
            return {
                ...state,
                collectionSearch: action.payload,
                offset: 0,
            }
        case SET_COLLECTION_CATEGORY:
            return {
                ...state,
                categoryFilter: action.payload,
                offset: 0,
            }
        case SET_COLLECTION_CHAIN:
            return {
                ...state,
                chainFilter: action.payload,
                offset: 0,
            }
        case SET_COLLECTION_TIMERANGE:
            return {
                ...state,
                timeRangeFilter: action.payload,
                offset: 0,
            }
        case SET_COLLECTION_OFFSET:
            return {
                ...state,
                offset: action.payload
            }
        case RESET_COLLECTION_OFFSET:
            return {
                ...state,
                offset: 0
            }
        case SET_COLLECTION_FILTER:
            return {
                ...state,
                filter: action.payload,
                offset: 0,
            }
        case SET_COLLECTION_SORT:
            return {
                ...state,
                sort: action.payload,
                offset: 0
            }

        case RESET_COLLECTION_DATA:
            return initialState;

        default:
            return state;
    }
};

export default AllCollections;
