import {
  SET_SELECTED_NFT_ID,
  SET_SELECTED_NFT_DATA,
  RESET_NFT_DATA
} from "../action/actionConstants";

const initialState = {
  nftId: null,
  nftData: null,  
};

const NftReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_NFT_ID:
      return {
        ...state,
        nftId: action.payload
      };
    case SET_SELECTED_NFT_DATA:
      return {
        ...state,
        nftData: action.payload
      };    
    case RESET_NFT_DATA:
      return {
        nftId: null,
        nftData: null,         
      };    
    default:
      return state;
  }
};

export default NftReducer;
