import RoundImg from "@components/CardUtils/RoundImg";
import { setCreatorDetails } from "@redux/action";
import { postApiReq } from "@utils/apiHandler";
import { cutString } from "@utils/basicReq";
import { imageHandler } from "@utils/formatter";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const ExploreCard = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userDetails);
  const [followingUser, setFollowingUser] = useState(false)
  const creatorData = item

  useEffect(() => {
    if (item)
      setFollowingUser(Boolean(item?.isFollowing))
  }, [item])

  const handleToggleFollowing = () => {
    if (Boolean(Cookies.get("user-data"))) {
      postApiReq(`/user/follow/${creatorData?._id}`, '')
        .then((res) => {
          if (res.status) {
            setFollowingUser(!followingUser)
          }
          else {

          }

        })
    }
    else {
      history.push(`/login?referrer=${history.location.pathname}`)
    }
  }


  if (item)
    return (
      <div className="border-2 max-w-[384px] flex-col p-4 rounded-[16px] shadow-lg">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            {" "}
            <div className="relative">
              <RoundImg item={item?.profile} />
              <img
                className="absolute right-0 bottom-0"
                src="./images/explore/right.png"
                alt=""

              />
            </div>
            <span className="shrink-0">
              <h4>{item?.username}</h4>
              <p className="text-[14px] text-[#7A798A]">{cutString((item?._id), 10)}</p>
            </span>
          </div>
          <div>
            {user?._id != item?._id && <div>
              {followingUser ? <button className="btn border border-theme-primary  rounded-full text-[16px] h-[46px] w-[120px]" onClick={handleToggleFollowing}>
                Following
              </button> : <button className="btn border border-theme-primary  rounded-full text-[16px] h-[46px] w-[120px]" onClick={handleToggleFollowing}>
                Follow
              </button>}
            </div>}
          </div>
        </div>
        <div className="flex gap-2 pt-[15px]" onClick={() => history.push(`/user/${item?.username || item?.address}`)}>
          <div>
            <img
              className="w-[192px] h-[300px] rounded-xl object-cover"
              src={`${item?.banner ? imageHandler(item?.banner, 2) : './images/explore/crispin_card1.png'}`}
              alt=""
            />
          </div>
          <div className="flex gap-2 flex-col">
            <img
              className="w-[152px] h-[100px] rounded-xl object-cover"
              src={`${item?.banner ? imageHandler(item?.banner, 2) : './images/explore/crispin_card1.png'}`}
              alt=""
            />
            <img
              className="w-[152px] h-[190px] rounded-xl object-cover"
              src={`${item?.banner ? imageHandler(item?.banner, 2) : './images/explore/crispin_card1.png'}`}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  return (
    <div className="text-center">
      <h3>No Artist to display</h3>
    </div>
  )
};
