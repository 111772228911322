/* Notification toast*/
export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TEST";

/* Homepage top NFT*/
export const SET_TOP_CATEGORY = "SET_TOP_CATEGORY";
export const SET_TOP_CREATORS = "SET_TOP_CREATORS";
export const SET_TOP_TRENDING = "SET_TOP_TRENDING";
export const SET_TOP_BLOG = "SET_TOP_BLOG";

/** Get Homepage top NFT */
export const GET_TOP_CATEGORY = "GET_TOP_CATEGORY";
export const GET_TOP_CREATORS = "GET_TOP_CREATORS";
export const GET_TOP_TRENDING = "GET_TOP_TRENDING";
export const GET_TOP_BLOG = "GET_TOP_BLOG";

/**Wallet Account */
export const SET_WALLET_ID = "SET_WALLET_ID";

/** USER DETAILS*/
export const SET_USER_TOKEN = "SET_USER_TOKEN"
export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const GET_USER_DETAILS = "GET_USER_DETAILS"

/** User Collection */
export const SET_USER_COLLECTION = "SET_USER_COLLECTION"

/** Collection*/
export const SET_SELECTED_COLLECTION_ID = "SET_SELECTED_COLLECTION_ID"
export const SET_SELECTED_COLLECTION_DATA = "SET_SELECTED_COLLECTION_DATA"
export const SET_SELECTED_COLLECTION_NFT = "SET_SELECTED_COLLECTION_NFT"
export const GET_USER_COLLECTION = "GET_USER_COLLECTION"
export const SET_CREATOR_COLLECTION = "SET_CREATOR_COLLECTION"

/**NFT */
export const SET_SELECTED_NFT_ID = "SET_SELECTED_NFT_ID"
export const SET_SELECTED_NFT_DATA = "SET_SELECTED_NFT_DATA"
export const RESET_NFT_DATA = "RESET_NFT_DATA"

/**Wallet connect Action */
export const OPEN_WALLET_MODAL = "OPEN_WALLET_MODAL";
export const CLOSE_WALLET_MODAL = "CLOSE_WALLET_MODAL";

export const SET_CREATOR_DETAILS = "SET_CREATOR_DETAILS";
export const GET_CREATOR_DETAILS = "GET_CREATOR_DETAILS";

/** ALL COLLECTION*/
export const SET_ALL_COLLECTIONS = "SET_ALL_COLLECTIONS";
export const GET_ALL_COLLECTIONS = "GET_ALL_COLLECTIONS";
export const SET_COLLECTION_SEARCH = "SET_COLLECTION_SEARCH";
export const SET_COLLECTION_CATEGORY = "SET_COLLECTION_CATEGORY";
export const SET_COLLECTION_OFFSET = "SET_COLLECTION_OFFSET";
export const SET_COLLECTION_FILTER = "SET_COLLECTION_FILTER";
export const RESET_COLLECTION_DATA = "RESET_COLLECTION_DATA";


/** Create Sell Order */
export const DISPATCH_SELL_ORDER = "DISPATCH_SELL_ORDER";
export const CREATE_SELL_ORDER = "CREATE_SELL_ORDER";
export const RESET_SELL_ORDER = "RESET_SELL_ORDER"

/** explore creator */
export const GET_EXPLORE_CREATOR = "GET_EXPLORE_CREATOR";
export const SET_EXPLORE_CREATOR = "SET_EXPLORE_CREATOR";
export const SET_ARTIST_OFFSET = "SET_ARTIST_OFFSET";
export const SET_ARTIST_SEARCH = "SET_ARTIST_SEARCH";
export const RESET_ARTIST_DATA = "RESET_ARTIST_DATA";

/**all nft */
export const GET_ALL_NFT = "GET_ALL_NFT";
export const SET_ALL_NFT = "SET_ALL_NFT";
export const SET_NFT_SEARCH = "SET_NFT_SEARCH";
export const SET_NFT_OFFSET = "SET_NFT_OFFSET";
export const SET_NFT_FILTER = "SET_NFT_FILTER";


/**collection nft  */
export const SET_COLLECTION_NFT_OFFSET = "SET_COLLECTION_NFT_OFFSET";
export const SET_COLLECTION_NFT_FILTER = "SET_COLLECTION_NFT_FILTER";

export const SET_IS_LOADING = "SET_IS_LOADING";

export const SET_COLLECTION_CHAIN = "SET_COLLECTION_CHAIN";
export const SET_COLLECTION_TIMERANGE = "SET_COLLECTION_TIMERANGE";
export const SET_COLLECTION_SORT = "SET_COLLECTION_SORT";
export const SET_CREATOR_COLLECTION_OFFSET = "SET_CREATOR_COLLECTION_OFFSET";
export const SET_USER_COLLECTION_OFFSET = "SET_USER_COLLECTION_OFFSET";

export const RESET_COLLECTION_OFFSET = "RESET_COLLECTION_OFFSET";
export const SET_COLLECTION_NFT_SEARCH = 'SET_COLLECTION_NFT_SEARCH';