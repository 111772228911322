import {
  SET_IS_LOADING,
} from "../action/actionConstants";

const initialState = {
  isLoading:false
};

const LoadingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default LoadingReducer;
