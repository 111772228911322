import React from "react";

import { postReq } from '@utils/apiHandler';
import { showToast } from '@redux/action';
import { useDispatch, } from "react-redux";


const ContactUs = () => {
  const [email, setEmail] = React.useState('')

  const dispatch = useDispatch();

  const handleSubscribe = () => {

    postReq('/subscribe', { email: email })
      .then(res => {
        if (res.status) {
          dispatch(showToast({ type: 'success', message: res.data }))
          setEmail('')
        }
        else
          dispatch(showToast({ type: 'error', message: 'Please enter valid email address' }))
      })
  }
  return (
    <div className="lg:pb-20 tablet:pb-10 pb-6">
      <div className="relative  tablet:min-h-[400px] min-h-[251px] flex flex-col items-start justify-center">
        <img
          className="absolute-img "
          src="/images/explore/explore_contact_us.png"
          alt=""
        />
        <div className="relative px-6 lg:pl-[100px]  md:pl-[50px]">
          <span className="text-theme-textlight subtitle1">Subscribe</span>
          <h1 className="tablet:text-[40px] text-[18px] pt-[4px]">
            Get the latest updates from the market
          </h1>
          <div className="relative">
            <input
              className="tablet:max-w-[614px] max-w-[250px] mt-[28px] rounded-full relative text-[18px] tablet:h-[54px] h-[45px] focus:border-theme-input lg:pr-[55px] tablet:pr-[100px] pr-[61px]"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Insert your email"
            />
            <span className="absolute tablet:top-[32%] tablet:right-[-2%] top-[35%] right-[-5px]">
              <button onClick={handleSubscribe} className="btn btn-primary rounded-full tablet:h-[58px] px-5 tablet:px-10">
                Sign up
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
