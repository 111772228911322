const dev = {
  BASE_URL: "https://api.artzcape.com",
  LIVE_URL: "https://artzcape.com/",
  INFURA_KEY: "https://rinkeby.infura.io/v3/e7dff36398c0441f91f7cce00b4109e0"

};

export const chainId = {
  'POLYGON': '0x89',
}

export const contracts = {
  'ETHEREUM': {
    WyvernProxyRegistry: '0x3E86715D2858b2d1Fe075a536BC9a86830b8a67D',
    WyvernExchange: '0x8474Cf2e5aD5aAcB07628569a2E0dC5996665a8D',
    AssetContractShared: '0x4e58a8024ABb96169CEe83932523AC478956563c',
    WyvernTokenTransferProxy: '0xeD8373Db9Ce2ADe634242Dd3eB3eE69944F5a19b'
  },
  'POLYGON': {
    WyvernProxyRegistry: '0xb2add295e9953f3D1DcCC1Cd8F4A0fbE2ED8204d',
    WyvernExchange: '0x3E9d6e81dF52937809c9970a75a987eB7b3cEb63',
    AssetContractShared: '0x4DDdD55DE3dbBB2c418837CB5e447135fD330509',
    WyvernTokenTransferProxy: "0x1b421F4dA14E18160f733c47ae99c6B583AfFa3e",
  }
}

export const feeRecipient = "0xD7d17421d2Eb20f6C046Aa76A93e01F89C8Ab43c";
export const zeroAddress = "0x0000000000000000000000000000000000000000";
export const noAddress = null

/**
 * Deployed exchange contract on network
 */


export const tokenAddress = {
  ETHEREUM: {
    ETH: zeroAddress,
    WETH: '0xc778417E063141139Fce010982780140Aa0cD5Ab'
  },
  POLYGON: {
    MATIC: zeroAddress,
    WMATIC: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
  }
};


const ENV = dev;

export default ENV;
