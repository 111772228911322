import { combineReducers } from 'redux';
import ToastReducer from "./reducers/ToastReducer";
import TopNftReducer from "./reducers/TopNftReducer"
import WalletReducer from './reducers/WalletReducer';
import UserDetails from './reducers/UserDetails';
import CollectionReducer from './reducers/CollectionReducer'
import NftReducer from './reducers/NftReducer'
import WalletModalReducer from './reducers/WalletModalReducer';
import CreatorDetails from './reducers/CreatorDetails';
import AllCollections from './reducers/AllCollections';
import CreateSellOrder from './reducers/CreateSellOrder';
import AllNftReducer from './reducers/AllNftReducer';
import LoadingReducer from './reducers/LoadingReducer'

const rootReducer = combineReducers({
  toastReducer: ToastReducer,
  topNftReducer: TopNftReducer,
  walletReducer: WalletReducer,
  userDetails: UserDetails,
  collectionReducer: CollectionReducer,
  nftReducer: NftReducer,
  walletModalReducer: WalletModalReducer,
  creatorDetails: CreatorDetails,
  allCollections: AllCollections,
  createSellOrder: CreateSellOrder,
  allNftReducer: AllNftReducer,
  loadingReducer: LoadingReducer

});

export default rootReducer;

