import {
  SET_TOP_CATEGORY,
  SET_TOP_CREATORS,
  SET_TOP_TRENDING,
  SET_TOP_BLOG,
} from "../action/actionConstants";

const initialState = {
  topCategory: [],
  topCreators: [],
  topTrending: [],
  topBlog: [],
};

const TopNftReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TOP_CATEGORY:
      return {
        ...state,
        topCategory: action.payload
      };
    case SET_TOP_CREATORS:
      return {
        ...state,
        topCreators: action.payload
      };
    case SET_TOP_TRENDING:
      return {
        ...state,
        topTrending: action.payload
      };
    case SET_TOP_BLOG:
      return {
        ...state,
        topBlog: action.payload
      };
    default:
      return state;
  }
};

export default TopNftReducer;
