import { DISPATCH_SELL_ORDER, CREATE_SELL_ORDER, RESET_SELL_ORDER } from "../action/actionConstants";

const initialState = {
    dispatchData: null,
    response: null,
};
const CreateSellOrder = (state = initialState, action = {}) => {
    switch (action.type) {
        case DISPATCH_SELL_ORDER:
            return {
                ...state,
                dispatchData: action.payload
            };
        case CREATE_SELL_ORDER:
            return {
                ...state,
                response: action.payload
            };
        case RESET_SELL_ORDER:
            return {
                state: initialState
            };
        default:
            return state;
    }
};

export default CreateSellOrder;
