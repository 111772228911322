import {
  SET_SELECTED_COLLECTION_ID,
  SET_SELECTED_COLLECTION_DATA,
  SET_SELECTED_COLLECTION_NFT,
  SET_COLLECTION_NFT_OFFSET,
  SET_COLLECTION_NFT_FILTER,
  SET_COLLECTION_NFT_SEARCH
} from "../action/actionConstants";

const initialState = {
  collectionId: null,
  collectionData: null,
  collectionNFT: [],
  collectionFilter: [],
  offset: 0,
  filter: {},
  search: ''
};

const CollectionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_COLLECTION_ID:
      return {
        ...state,
        collectionId: action.payload,
        offset: 0
      };
    case SET_COLLECTION_NFT_SEARCH:
      return {
        ...state,
        search: action.payload
      }
    case SET_SELECTED_COLLECTION_DATA:
      return {
        ...state,
        collectionData: action.payload,
        offset: 0
      };
    case SET_SELECTED_COLLECTION_NFT:
      return {
        ...state,
        collectionNFT: state.offset === 0 ? action.payload : state.collectionNFT.concat(action.payload),
      };
    case SET_COLLECTION_NFT_OFFSET:
      return {
        ...state,
        offset: action.payload,
      }
    case SET_COLLECTION_NFT_FILTER:
      return {
        ...state,
        filter: action.payload,
        offset: 0
      }
    default:
      return state;
  }
};

export default CollectionReducer;
