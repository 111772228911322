import BreadCrumb from "@components/BreadCrumb";
import { ExploreCard } from "@components/Cards/ExploreCard";
import ContactUs from "@components/ContactUs";
import Loader from "@components/Loader";
import { getExploreCreator, resetArtistData, setArtistOffset, setArtistSearch, setCreatorDetails, setIsLoading } from "@redux/action";
import { postApiReq } from "@utils/apiHandler";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isUserLoggedIn } from '@utils/basicReq'
import { icons } from "./../../utils/icons";

const ExploreArtists = () => {
  const dispatch = useDispatch();
  const [showLoadMorButton, setShowLoadMorButton] = useState(true)
  const { creatorData, exploreCreator, search, offset, isLoading } = useSelector((state) => state.creatorDetails);
  const { user } = useSelector((state) => state.userDetails);
  useEffect(() => {
    if (isUserLoggedIn()) {
      if (user) {
        dispatch(getExploreCreator());
      }
    }
    else {
      dispatch(getExploreCreator());
    }

    return () => {
      dispatch(resetArtistData());
    }
  }, [user]);

  const getMoreArtist = () => {
    if (exploreCreator?.length > 9)
      setIsLoading(true);
    dispatch(setArtistOffset(offset + 10));
    setIsLoading(false);
  }

  const handleSearchChange = (e) => {
    setIsLoading(true);
    dispatch(setArtistSearch(e.target.value));
    setIsLoading(false);
  }
  useEffect(() => {
    if (exploreCreator) {
      if ((offset + 10) > exploreCreator.length) {
        setShowLoadMorButton(false)
      }
      else{
        setShowLoadMorButton(true)
      }
    }
}, [exploreCreator])

return (
  <>
    <Loader isLoading={isLoading} />
    <div className="container">
      <div className="flex">
        <BreadCrumb />
      </div>

      <div className="flex justify-between flex-col tablet:flex-row tablet:items-center gap-4">
        <h2>Explore artists</h2>
        <div className="relative ">
          <input
            type="text"
            className="rounded-full tablet:w-[387px] w-full pl-[40px]"
            placeholder="Search artist"
            value={search}
            onChange={handleSearchChange}
          />
          <span className="text-[25px] w-[19px] h-[19px] rounded-full center cursor-pointer absolute top-0 translate-y-4 left-[15px]">
            {icons.search}
          </span>
        </div>
      </div>
      {exploreCreator?.length > 0 ?
        <div className="grid lg:grid-cols-3 xxl:lg:grid-cols-4 tablet:grid-cols-2 grid-cols-1 lg:gap-8 gap-6 tablet:gap-6 mt-12">
          {exploreCreator?.length > 0 && exploreCreator?.map((item, index) => (
            <ExploreCard item={item} user={user} />
          ))}
        </div> :
        <div className="text-center mt-12">
          <h3>No Artist to display</h3>
        </div>}
      <div className="flex justify-center items-center lg:py-16 py-6 tablet:py-10">
        {showLoadMorButton &&
          <button className="btn border border-theme-primary  rounded-full text-[20px] px-[30px] py-[15px] hover:text-theme-textlight/50 transition-all" onClick={() => getMoreArtist()}>
            Load more
          </button>}
      </div>
      <ContactUs />
    </div>
  </>
);
};

export default ExploreArtists;
