import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const BreadCrumb = () => {
  let { pathname } = useLocation();
  return (
    <nav className="bg-grey-light rounded-md  my-5">
      <ul className="list-reset flex">
        <li>
          <NavLink to="/" className="">
            Home
          </NavLink>
        </li>
        <li>
          <span className="text-gray-500 mx-4 align-middle inline-flex">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 1.5L5 6L0.5 10.5L1.70697 11.707L7.41397 6.00003L1.70697 0.293031L0.5 1.5Z"
                fill="#131D23"
              />
            </svg>
          </span>
        </li>
        {pathname === "/nft-detail" && (
          <li>
            <NavLink to="#" className="">
              NFT Detail
            </NavLink>
          </li>
        )}
        {pathname === "/explore-artist" && (
          <li>
            <NavLink to="#" className="text-theme-primary/60">
              Artists
            </NavLink>
          </li>
        )}
        {pathname === "/blog" && (
          <li>
            <NavLink to="#" className="text-theme-primary/60">
              Blog
            </NavLink>
          </li>
        )}
        {pathname === "/help" && (
          <li>
            <NavLink to="#" className="text-theme-primary/60">
              Support
            </NavLink>
          </li>
        )}
        {pathname === "/explore-collection" && (
          <li>
            <NavLink to="#" className="text-theme-primary/60">
              Collections
            </NavLink>
          </li>
        )}
        {pathname === "/article" && (
          <li>
            <NavLink to="#" className="text-theme-primary/60">
              Adipiscingn condimentum
            </NavLink>
          </li>
        )}
        {pathname === "/getting-started" && (
          <li>
            <NavLink to="#" className="text-theme-primary/60">
              Getting started
            </NavLink>
          </li>
        )}
        {pathname === '/user-profile' && <li><NavLink to="/user-profile" className="">User</NavLink></li>}
        {pathname === '/request-list' &&<> <li><NavLink to="/submit-request" className="">Submit Your Requests</NavLink></li> 
        <li>
          <span className="text-gray-500 mx-4 align-middle inline-flex">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 1.5L5 6L0.5 10.5L1.70697 11.707L7.41397 6.00003L1.70697 0.293031L0.5 1.5Z"
                fill="#131D23"
              />
            </svg>
          </span>
        </li>
        <li><NavLink to="#" className="">View Requests</NavLink></li> 
        </>}

      </ul>
    </nav>
  );
};

export default BreadCrumb;
