import React from "react";

import { FiArrowRight, FiArrowLeft, FiChevronRight } from "react-icons/fi";
import { BiWalletAlt, BiPencil, BiTimeFive } from "react-icons/bi";
import { MdLockOpen } from "react-icons/md";

import {
  FaFacebookF,
  FaLinkedinIn,
  FaCircleNotch,
  FaSpinner,
} from "react-icons/fa";
import { GrGallery } from "react-icons/gr";
import {
  BsInfoCircle,
  BsArrowRightShort,
  BsTelegram,
  BsLink,
} from "react-icons/bs";
import { SiDiscord } from "react-icons/si";

import {
  AiFillHeart,
  AiOutlinePlus,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineAlignRight,
  AiFillMediumCircle,
} from "react-icons/ai";
export const icons = {
  Lock: <MdLockOpen />,
  wallet: <BiWalletAlt />,
  search: <AiOutlineSearch />,
  plus: <AiOutlinePlus />,
  facebook: <FaFacebookF />,
  linkedin: <FaLinkedinIn />,
  instagram: <AiOutlineInstagram />,
  twitter: <AiOutlineTwitter />,
  heart: <AiOutlineHeart />,
  heartfill: <AiFillHeart />,
  leftarrow: <FiArrowLeft />,
  rightarrow: <FiArrowRight />,
  check: <AiOutlineCheck />,
  info: <BsInfoCircle />,
  chevronright: <FiChevronRight />,
  close: <AiOutlineClose />,
  pencil: <BiPencil />,
  time: <BiTimeFive />,
  circlenotch: <FaCircleNotch />,
  spinner: <FaSpinner />,
  navbtn: <AiOutlineAlignRight />,
  arrowright: <BsArrowRightShort />,
  gallary: <GrGallery />,
  discord: <SiDiscord />,
  medium: <AiFillMediumCircle />,
  telegram: <BsTelegram />,
  yoursite: <BsLink />,
};
