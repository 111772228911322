import { zeroAddress } from "./env";
import Cookies from "js-cookie";

export const isUserLoggedIn = () => {
  return Boolean(Cookies.get("user-data"))
}

// export const isUserLoggedIn = () => {
//   if (localStorage.hasOwnProperty('user'))
//     return true
//   return false
// }

// export const cutString = (string, length) => {
//   var cut = string.indexOf(' ', length);
//   if (cut === -1) return string;
//   return string.substring(0, cut) + ('...')
// }
export const cutString = (string, length) => {
  if (typeof string != 'string')
    string = JSON.stringify(string)
  if (string?.length < length) return string;

  return string?.substring(0, length) + ('...')
}

export const renderCurrency = (chain, paymentToken) => {
  if (paymentToken === false) {
    if (chain === 'ETHEREUM')
      return 'ETH';
    else if (chain === 'POLYGON')
      return 'MATIC';
    return
  }
  else if (chain === 'ETHEREUM') {
    if (paymentToken === zeroAddress) {
      return 'ETH';
    }
    else {
      return 'WETH';
    }
  }
  else if (chain === 'POLYGON') {
    if (paymentToken === zeroAddress) {
      return 'MATIC';
    }
    else {
      return 'WMATIC';
    }
  }
  else return
}

export const audioFormats = ['mp3', 'm4A',]

export const videoFormats = ['mp4', 'OGG']

export const imageFormate = ['jpg', 'jpeg', 'png', 'gif']

export const supportedFormate = ['jpg', 'jpeg', 'png', 'gif', 'webP', 'mp4', 'OGG']