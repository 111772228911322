import React from 'react'
import {
  Switch,
  Route
} from "react-router-dom";
import ProtectedRoutes from './ProtectedRoutes';

import NotFound from '@pages/notFound'
const UserRoutes = () => {
  return (
    <Switch>
      
      <Route path={'/*'} exact component={NotFound} />
    </Switch>
  )
}

export default UserRoutes
