import {
    SET_CREATOR_DETAILS, GET_CREATOR_DETAILS, SET_CREATOR_COLLECTION, SET_EXPLORE_CREATOR,
    SET_ARTIST_OFFSET,
    SET_ARTIST_SEARCH,
    RESET_ARTIST_DATA,
    SET_CREATOR_COLLECTION_OFFSET
} from "../action/actionConstants";

const initialState = {
    creatorId: '',
    creatorData: null,
    creatorCollection: null,
    exploreCreator: null,
    search: '',
    offset: 0,    
};
const CreatorDetails = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CREATOR_DETAILS:
            console.log('action', action)
            return {                
                ...state,
                creatorId: action.payload
            };
        case SET_CREATOR_COLLECTION:
            return {
                ...state,
                creatorCollection: state.offset === 0 ? action.payload : state.creatorCollection.concat(action.payload)
            }
        case GET_CREATOR_DETAILS:
            return {
                ...state,
                creatorData: action.payload
            };
        case SET_EXPLORE_CREATOR:
            return {
                ...state,
                exploreCreator: state.offset === 0 ? action.payload : state.exploreCreator.concat(action.payload)
            };
        case SET_ARTIST_SEARCH:
            return {
                ...state,
                search: action.payload,
                offset: 0
            };
        case SET_ARTIST_OFFSET:
            return {
                ...state,
                offset: action.payload
            };
        case SET_CREATOR_COLLECTION_OFFSET:
            return {
                ...state,
                offset:action.payload
            }
        case RESET_ARTIST_DATA:
            return initialState
        default:
            return state;
    }
};

export default CreatorDetails;
