import React, { useEffect, lazy, Suspense } from "react";
import { Alert as MuiAlert, Snackbar } from "@material-ui/core";
import { HIDE_TOAST } from "@redux/action/actionConstants";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoutes from "./ProtectedRoutes";
import UserRoutes from "./UserRoutes";
import '../node_modules/tw-elements/dist/js/index.min.js'
import "./input.css";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie"
import { setUserToken, closeWalletModal } from "@redux/action";
import Loader from "@components/Loader";

const NotFound = lazy(() => import("@pages/notFound"));
const Navbar = lazy(() => import("@components/layout/Navbar"));
const ExploreArtists = lazy(() => import("@pages/Artists/ExploreArtists"));
const SingleCollection = lazy(() => import("@pages/SingleCollection/SingleCollection"));
const ExploreCollection = lazy(() => import("@pages/ExploreCollection/ExploreCollection"));
const Footer = lazy(() => import("@components/layout/Footer"));
const MoreInfo = lazy(() => import("@pages/MoreInfo/MoreInfo"));
const Profile = lazy(() => import("@pages/Profile/Profile"));
const NFTDetail = lazy(() => import("@pages/NFTDetail/NFTDetail"));
const SubmitRequest = lazy(() => import("@pages/SubmitRequest/SubmitRequest"));
const Requests = lazy(() => import("@pages/Requests/Requests"));
const CreateCollection = lazy(() => import("@pages/CreateCollection/CreateCollection"));
const EditProfile = lazy(() => import("@pages/EditProfile/EditProfile"));
const Watchlist = lazy(() => import("@pages/Watchlist/Watchlist"));
const GettingStarted = lazy(() => import("@pages/GettingStarted/GettingStarted"));
const Help = lazy(() => import("@pages/Help/Help"));
const MyCollection = lazy(() => import("@pages/MyCollection"));
const Article = lazy(() => import("@pages/Article/Article"));
const Blog = lazy(() => import("@pages/Blog/Blog"));
const CreateItem = lazy(() => import("@pages/CreateItem/CreateItem"));
const ListItem = lazy(() => import("@pages/ListItem/ListItem"));
const ConnectWallet = lazy(() => import("./pages/ConnectWallet/ConnectWallet"));
const TopNft = lazy(() => import("@pages/TopNft/TopNft"));
const TopNftDetails = lazy(() => import("@pages/TopNftDetails/TopNftDetails"));
const Buying = lazy(() => import("@pages/Buying/Buying"));
const Selling = lazy(() => import("@pages/Selling/Selling"));
const Creating = lazy(() => import("@pages/Creating/Creating"));
const FAQ = lazy(() => import("@pages/FAQ/FAQ"));
const UserSafety = lazy(() => import("@pages/UserSafety/UserSafety"));
const ConnectWalletPopup = lazy(() => import("@components/Popup/ConnectWalletPopup"));
const ArtistPage = lazy(() => import("@pages/ArtistPage/ArtistPage"));
const AllNft = lazy(() => import("@pages/AllNFT/AllNft"));
const Reveal = lazy(() => import("@pages/Reveal"));
const Reveal2 = lazy(() => import("@pages/Reveal2"));
const CreatorEarning = lazy(() => import("@pages/CreatorEarning/CreatorEarning"));
const Home = lazy(() => import('@pages/Home/Home'));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AuthContext = React.createContext({});

const Routes = () => {
  const { pathname } = useLocation();
  window.web3 = new window.Web3(window.ethereum);

  const dispatch = useDispatch();
  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );


  const { isWalletOpen } = useSelector((state) => state.walletModalReducer);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };

  useEffect(() => {
    if (Boolean(Cookies.get("user-data"))) {
      const userToken = Cookies.get("user-data");
      dispatch(setUserToken(userToken));
    }
  }, [dispatch]);
  return (
    <>
      <Snackbar
        open={isVisible}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Suspense fallback={<Loader />}>
        <Navbar />
        <Switch>
          <Route path={"/"} exact component={Home} />
          <Route path={"/collection/:url"} exact component={SingleCollection} />
          <Route path={"/more-info"} exact component={MoreInfo} />
          <ProtectedRoutes path={"/user-profile"} exact component={Profile} />
          <ProtectedRoutes path={"/edit-profile"} exact component={EditProfile} />
          <ProtectedRoutes path={"/watchlist"} exact component={Watchlist} />
          <Route path={"/top-nft"} exact component={TopNft} />
          <Route path={"/top-nft-details"} exact component={TopNftDetails} />
          <ProtectedRoutes
            path={"/assets/:artId/sell"}
            exact
            component={ListItem}
          />
          <Route path={"/explore-artist"} exact component={ExploreArtists} />
          <Route path={"/art/:id"} exact component={NFTDetail} />
          <Route path={"/user/:url"} exact component={ArtistPage} />
          <Route path={"/all-nft"} exact component={AllNft} />
          <ProtectedRoutes
            path={"/create-collection"}
            exact
            component={CreateCollection}
          />
          <ProtectedRoutes
            path={"/update-collection/:url"}
            exact
            component={CreateCollection}
          />
          <ProtectedRoutes path={"/create-item"} exact component={CreateItem} />
          <ProtectedRoutes path={"/collections"} exact component={MyCollection} />
          <Route path={"/login"} exact component={ConnectWallet} />
          <ProtectedRoutes
            path={"/submit-request"}
            exact
            component={SubmitRequest}
          />
          <ProtectedRoutes path={"/request-list"} exact component={Requests} />

          <Route
            path={"/explore-collection"}
            exact
            component={ExploreCollection}
          />
          <Route path={"/user"} render={(props) => <UserRoutes {...props} />} />
          <Route path={"/blog"} exact component={Blog} />
          <Route path={"/blog-details"} exact component={Article} />
          <Route path={"/help"} exact component={Help} />
          <Route path={"/getting-started"} exact component={GettingStarted} />
          <Route path={"/help-buying"} exact component={Buying} />
          <Route path={"/help-selling"} exact component={Selling} />
          <Route path={"/help-creating"} exact component={Creating} />
          <Route path={"/faq"} exact component={FAQ} />
          <Route path={"/user-safety"} exact component={UserSafety} />
          <Route path={"/reveal"} exact component={Reveal} />
          <Route path={"/reveal2"} exact component={Reveal2} />
          <Route path={"/collection/:id/payouts"} exact component={CreatorEarning} />

          {/* <ProtectedRoutes path={"/profile"} exact component={Profile} /> */}
          <Route path={"/*"} exact component={NotFound} />
        </Switch>
        <Footer />
        {isWalletOpen && (
          <ConnectWalletPopup
            isOpen={isWalletOpen}
            onClose={() => dispatch(closeWalletModal())}
          />
        )}
      </Suspense>      
    </>
  );
};

const App = () => {
  const history = useHistory();
  const [user, setUser] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const logout = () => {
    setIsLoading(true);
    Cookies.remove("user-data");
    history.push("/");
    window.location.reload();
    setIsLoading(false);
  };
  return (
    <Router>
      <AuthContext.Provider value={{ user, setUser, logout }}>
        <Routes />
        {isLoading && <Loader isLoading={isLoading} />}
      </AuthContext.Provider>
    </Router>
  );
};
export default App;
