import ENV from './env';
export const imageHandler = (path, type) => {

    switch (type) {
        case 1:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/user/profile/${path}`;
            }
            else {
                return path;
            }

        case 2:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/user/banner/${path}`;
            }
            else {
                return path;
            }

        case 3:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/user/news/${path}`;
            }
            else {
                return path;
            }

        case 4:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/user/verification/${path}`;
            }
            else {
                return path;
            }

        case 5:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/collection/logo/${path}`;
            }
            else {
                return path;
            }

        case 6:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/collection/featured/${path}`;
            }
            else {
                return path;
            }

        case 7:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/collection/banner/${path}`;
            }
            else {
                return path;
            }

        case 8:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/token/${path}`;
            }
            else {
                return path;
            }


        case 9:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/ticket/attachment/${path}`;
            }
            else {
                return path;
            }
        case 10:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/token/thumbnail/${path}`;
            }
            else {
                return path;
            }

        default:
            if (path && path.indexOf('http') === -1) {
                return `${ENV.BASE_URL}/${path}`;
            }
            else {
                return path;
            }

    }

}